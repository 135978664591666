import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    let links = this.element.getElementsByTagName('a')
    for (var i = 0; i < links.length; i++) {
      let link = links[i]
      if (link.hostname != window.location.hostname) {
        link.target = '_blank'
      }
    }
  }
}
