import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['form']

  connect() {
    this.element.addEventListener('ajax:success', this.onPostSuccess)
    this.element.setAttribute('data-remote', true)
    this.partial = document.getElementById(`partial:${this.data.get('partial')}`)
    this.empty   = document.getElementById(`partial:${this.data.get('partial')}:empty`)
    this.method  = (this.element.querySelector('[name=_method]')||{}).value || this.element.method || this.element.dataset.method
  }

  onPostSuccess = (event) => {
    let [data, status, xhr] = event.detail

    // Update DOM
    switch (this.method) {
      case 'post':
        this.addPartial(xhr)
        break
      case 'patch':
        this.updatePartial(xhr)
      case 'delete':
        this.removePartial(xhr)
    }

    // Close Modal
    let modalController = this.application.getControllerForElementAndIdentifier(document.querySelector("[data-application-target='modal']"), "modal")
    modalController.close()
  }

  addPartial(xhr) {
    var div = document.createElement('div')
    div.innerHTML = xhr.response.trim()
    var el = div.firstChild
    this.partial.insertBefore(el, this.empty)
    this.highlight(el)
  }

  updatePartial(xhr) {
    var div = document.createElement('div')
    div.innerHTML = xhr.response.trim()
    var el = div.firstChild
    this.partial.parentNode.replaceChild(el, this.partial)
    this.highlight(el)
  }

  removePartial(xhr) {
    let partial = this.partial
    this.highlight(partial, 'is-removed', function() { partial.remove() })
  }

  highlight(el, klass='is-highlighted', callback=null) {
    el.classList.add(klass)
    el.addEventListener('animationend', this.removeHighlight(klass, callback), { once: true })
    el.addEventListener('webkitAnimationEnd', this.removeHighlight(klass, callback), { once: true })
    el.addEventListener('mozAnimationEnd', this.removeHighlight(klass, callback), { once: true })
    el.addEventListener('MSAnimationEnd', this.removeHighlight(klass, callback), { once: true })
    el.addEventListener('oanimationend', this.removeHighlight(klass, callback), { once: true })
  }

  removeHighlight = (klass, callback) => {
    return function curried_func(event) {
      let el = event.target
      el.removeEventListener('animationend', this.removeHighlight, { once: true })
      el.removeEventListener('webkitAnimationEnd', this.removeHighlight, { once: true })
      el.removeEventListener('mozAnimationEnd', this.removeHighlight, { once: true })
      el.removeEventListener('MSAnimationEnd', this.removeHighlight, { once: true })
      el.removeEventListener('oanimationend', this.removeHighlight, { once: true })
      el.classList.remove(klass)
      if (callback != null) {
        callback()
      }
    }
  }
}
